
<template>
  <div class="results-summary">
    <div class="card mb-1">
      <div class="card-body">
        <div>
          <div
            v-if="visibleRecordsCount==results.length"
            class="record-count"
          >
            Showing all {{ visibleRecordsCount }} matching records.
          </div>
          <div
            v-else
            class="record-count"
          >
            Showing first {{ visibleRecordsCount }} of {{ results.length }} matching records.
          </div>
          <div class="switch switch-sm">
            <input
              id="show-all-results"
              v-model="options.show_all_results"
              type="checkbox"
              class="switch"
            >
            <label for="show-all-results">Show all matches</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    name: "ResultsSummary", 
    props: {
        results: { type: Array, default: null },
        visibleRecordsCount: { type: Number, default: 0 },
        options: { type: Object, default: null }
    }
}
</script>

